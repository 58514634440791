import React, {useEffect, useMemo, useState, FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AppDatePickerField} from '../../../../components/form/AppDatePickerField';
import {AppTextField} from '../../../../components/form/AppTextField';
import {DateOfBirthPickerField} from '../../../../components/form/DateOfBirthPickerField';
import {FormRow} from '../../../../components/form/FormRow';
import {FormSection} from '../../../../components/form/FormSection';
import {trimAndCapitalizeFirstLetter} from '../../../../utils/utils';
import {BranchOfIndustrySelectField} from '../../../reference/BranchOfIndustrySelectField';
import {LegalFormSelectField} from '../../../reference/LegalFormSelectField';
import {PersonTitleSelectField} from '../../../reference/PersonTitleSelectField';
import {SalutationSelectField} from '../../../reference/SalutationSelectField';
import {ReadOnlyProps} from '../../ApplicationForm';
import {ApplicationTypeDto, CardDispatchDto, DictionaryValueDto} from '../../../../api';
import AppConfig from '../../../../config';
import {Typography} from '@mui/material';
import {AppRadioButtonField} from '../../../../components/form/AppRadioButtonField';
import {markLabelAsMandatory} from '../../../../components/form/utils';
import {AppAmountField, AppAmountFieldProps} from '../../../../components/form/AppAmountField';
import {PlaceOfRegistrationSelectField} from '../../../reference/PlaceOfRegistrationSelectField';
import {BaseMainProfiCardApplicationUnion} from '../model';
import {AddressForm} from '../../AddressForm';
import {ContactForm} from '../../ContactForm';
import {BaseMainProfiCardApplication, isCompanyOwnerRequired, MainCardholder} from './model';
import {CountryKeyCH, CountryKeyDE, CountryUtils} from '../../../country';
import {useClearDependentFormFields} from '../../../../components/form/useClearDependentFormFields';
import {ANY_VALUE_KEY} from '../../../reference/model';
import {MainProfiCardDeApplication} from './de/model';
import {AddressWithAdditionalLine} from '../../../auxiliary/model';
import {NationalitySelectField} from '../../../reference/NationalitySelectField';

export const CompanyDataFormSection: FC<ReadOnlyProps & {
    application: BaseMainProfiCardApplicationUnion
}> = ({readOnly, application}) => {
    const {t} = useTranslation();

    const isCreatedBySmartSignUp = !!application.isCreatedBySmartSignUp;
    return (<FormSection title={t('application.form.companyData.sectionTitle')}>
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={8}
                                                             required={true}
                                                             label={t('application.form.companyData.companyName')}
                                                             fieldPath={'company.companyName'}
                                                             helperText={isCreatedBySmartSignUp ?
                                                                     <Typography
                                                                             color={theme => theme.palette.success.main}>
                                                                         {t('application.form.companyData.nameFromSmartSignUpHelperText')}
                                                                     </Typography>
                                                                     : t('application.form.companyData.nameHelperText')}
                                                             readOnly={readOnly || isCreatedBySmartSignUp}
            />
        </FormRow>

        <AddressAndLegalForm readOnly={readOnly}
                             applicationType={application.applicationType}/>

        <BranchOfIndustrySelectField<BaseMainProfiCardApplicationUnion>
                branchCategoryFieldPath={'company.branchCategory'}
                branchOfIndustryFieldPath={'company.branchOfIndustry'}
                required={true}
                readOnly={readOnly}/>
    </FormSection>);
};

export const getAvailableCountries = (applicationType: ApplicationTypeDto): DictionaryValueDto[] | undefined => {
    switch (applicationType) {
        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
            return [
                {key: CountryUtils.DE.code, value: 'Deutschland'},
                {key: CountryUtils.CH.code, value: 'Schweiz'},
                {key: CountryUtils.LU.code, value: 'Luxemburg'},
                {key: CountryUtils.FR.code, value: 'Frankreich'},
                {key: CountryUtils.BE.code, value: 'Belgien'},
                {key: CountryUtils.AT.code, value: 'Oesterreich'}
            ];
        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
            return [
                {key: CountryUtils.LU.code, value: 'Luxemburg'},
                {key: CountryUtils.DE.code, value: 'Deutschland'}
            ];
        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
            return [
                {key: CountryUtils.CH.code, value: 'Schweiz'},
                {key: CountryUtils.DE.code, value: 'Deutschland'}
            ];
        default:
            return undefined;
    }
};

function AddressAndLegalForm({readOnly, applicationType}: ReadOnlyProps & { applicationType: ApplicationTypeDto }) {
    const {watch} = useFormContext<BaseMainProfiCardApplication>();
    const isAddressSelected = watch('company.address.street');
    const availableCountries = useMemo(() => getAvailableCountries(applicationType), [applicationType]);
    const selectedCountry = watch('company.address.country.key');

    useClearDependentFormFields<BaseMainProfiCardApplication>('company.address.country.key', 'company.legalForm');

    return (
            <>
                <AddressForm<BaseMainProfiCardApplicationUnion> basePath={'company.address'}
                                                                availableCountries={availableCountries}
                                                                showAdditionalLine={true}
                                                                required={true}
                                                                readOnly={readOnly}/>
                <FormRow>
                    <LegalFormSelectField<BaseMainProfiCardApplicationUnion> fieldPath={'company.legalForm'}
                                                                             countryKey={selectedCountry}
                                                                             required={true}
                                                                             disabled={!isAddressSelected}
                                                                             readOnly={readOnly}/>
                </FormRow>
            </>
    );
}

export const BillingAddressFormSection: FC<ReadOnlyProps> = ({readOnly}) => {
    const {t} = useTranslation();
    return (<FormSection<BaseMainProfiCardApplicationUnion>
            title={t('application.form.billingAddress.sectionTitle')}
            optional={{
                optionalFieldPath: 'isAdditionalBillingAddress',
                optionalCheckboxLabel: t('application.form.billingAddress.differentBillingAddress'),
                readOnly: readOnly,
            }}
    >
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={4}
                                                             required={true}
                                                             label={t('application.form.billingAddress.contactPersonName')}
                                                             fieldPath={'billingAddress.contactPersonName'}
                                                             readOnly={readOnly}
            />
        </FormRow>
        <AddressForm<BaseMainProfiCardApplicationUnion> basePath={'billingAddress.address'} showAdditionalLine={false}
                                                        required={true}
                                                        readOnly={readOnly}/>
        <ContactForm<BaseMainProfiCardApplicationUnion> basePath={'billingAddress'} readOnly={readOnly}/>
    </FormSection>);
};

export const MainCardholderFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.cardHolder.main.sectionTitle')}>
        <FormRow>
            <SalutationSelectField<BaseMainProfiCardApplicationUnion>
                    breakpoint={3}
                    fieldPath={'cardHolder.salutation'}
                    required={true}
                    readOnly={props.readOnly}/>
            <PersonTitleSelectField<BaseMainProfiCardApplicationUnion>
                    breakpoint={3}
                    fieldPath={'cardHolder.title'}
                    readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             label={t('common.form.person.firstName')}
                                                             fieldPath={'cardHolder.firstName'}
                                                             transformValue={trimAndCapitalizeFirstLetter}
                                                             readOnly={props.readOnly}/>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             label={t('common.form.person.lastName')}
                                                             fieldPath={'cardHolder.lastName'}
                                                             transformValue={trimAndCapitalizeFirstLetter}
                                                             readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                                       required={true}
                                                                       readOnly={props.readOnly}
                                                                       fieldPath={'cardHolder.dateOfBirth'}/>
        </FormRow>
        <ContactForm<BaseMainProfiCardApplicationUnion> basePath={'cardHolder'} readOnly={props.readOnly}/>
    </FormSection>);
};

export const CardDispatchFormSection: FC<ReadOnlyProps> = ({readOnly}) => {
    const {t} = useTranslation();

    if (!AppConfig.cardDispatchEnabled) {
        return null;
    }

    return (<FormSection title={t('application.form.additionalCard.cardDispatch.title')}>
        <FormRow>
            <AppRadioButtonField<CardDispatchDto, BaseMainProfiCardApplicationUnion>
                    label={markLabelAsMandatory(t('application.form.additionalCard.cardDispatch.title'), true)}
                    readOnly={readOnly}
                    fieldPath={'cardDispatch'}
                    options={[
                        {
                            label: t('application.form.additionalCard.cardDispatch.SHIPPING_TO_COMPANY'),
                            value: CardDispatchDto.SHIPPING_TO_COMPANY,
                        },
                        {
                            label: t('application.form.additionalCard.cardDispatch.SHIPPING_TO_MARKET'),
                            value: CardDispatchDto.SHIPPING_TO_MARKET,
                        },
                    ]}/>
        </FormRow>
    </FormSection>);
};

export const LegitimationFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.legitimation.sectionTitle')}>
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             readOnly={props.readOnly}
                                                             label={t('application.form.legitimation.idCardNumber')}
                                                             fieldPath={'legitimization.idCardNumber'}/>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             readOnly={props.readOnly}
                                                             label={t('application.form.legitimation.issuingAuthority')}
                                                             fieldPath={'legitimization.issuingAuthority'}/>
            <AppDatePickerField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                                   required={true}
                                                                   readOnly={props.readOnly}
                                                                   label={t('application.form.legitimation.dateOfIssue')}
                                                                   fieldPath={'legitimization.dateOfIssue'}
                                                                   disableFuture={true}/>
        </FormRow>
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             label={t('application.form.legitimation.commercialRegisterNumber')}
                                                             fieldPath={'legitimization.commercialRegisterNumber'}
                                                             readOnly={props.readOnly}/>
            <PlaceOfRegistrationInputField readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             readOnly={props.readOnly}
                                                             label={t('application.form.legitimation.name1Signature')}
                                                             fieldPath={'legitimization.name1Signature'}/>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             readOnly={props.readOnly}
                                                             label={t('application.form.legitimation.name2Signature')}
                                                             fieldPath={'legitimization.name2Signature'}/>
        </FormRow>
    </FormSection>);
};

export function getSupportedCountryKeyForPlaceOfRegistration(companyCountryKey: string | undefined): CountryKeyDE | CountryKeyCH | undefined {
    switch (companyCountryKey) {
        case CountryUtils.DE.code:
            return 'DE';
        case CountryUtils.CH.code:
            return 'CH';
        default:
            return undefined;
    }
}

const PlaceOfRegistrationInputField: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    const {getValues} = useFormContext<BaseMainProfiCardApplicationUnion>();
    const commercialRegisterNumber = getValues('legitimization.commercialRegisterNumber');
    const required = !!commercialRegisterNumber?.trim();
    const {watch, setValue} = useFormContext<BaseMainProfiCardApplication>();

    const companyCountryKey = watch('company.address.country.key');
    const optionsFromCountry = getSupportedCountryKeyForPlaceOfRegistration(companyCountryKey);

    const [oldValue, setOldValue] = useState(companyCountryKey);
    if (oldValue !== companyCountryKey) {
        setOldValue(companyCountryKey);
        if (optionsFromCountry === undefined) {
            setValue('legitimization.placeOfRegistration.key', ANY_VALUE_KEY);
            setValue('legitimization.placeOfRegistration.value', '');
        } else {
            setValue('legitimization.placeOfRegistration', null);
        }
    }

    if (optionsFromCountry !== undefined) {
        return (<PlaceOfRegistrationSelectField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                                                   fieldPath={'legitimization.placeOfRegistration'}
                                                                                   readOnly={props.readOnly}
                                                                                   optionsFromCountry={optionsFromCountry}
                                                                                   required={required}/>);
    }

    return (<AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             label={t('application.form.legitimation.placeOfRegistration')}
                                                             fieldPath={'legitimization.placeOfRegistration.value'}
                                                             required={required}
                                                             readOnly={props.readOnly}/>);
};


export const DesiredLimitFormSection: FC<ReadOnlyProps & Pick<AppAmountFieldProps, 'defaultCurrency'>> = (props) => {
    const {t} = useTranslation();

    return (<FormSection title={t('application.form.desiredLimit.main.sectionTitle')}>
        <FormRow>
            <AppAmountField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                               label={t('application.form.desiredLimit.sectionTitle')}
                                                               fieldPath={'desiredLimit'}
                                                               readOnly={props.readOnly}
                                                               required={true}
                                                               defaultCurrency={props.defaultCurrency}
            />
        </FormRow>
    </FormSection>);
};

export const CompanyOwnerFormSection: FC<ReadOnlyProps & { applicationType: ApplicationTypeDto }> = (props) => {
    const {t} = useTranslation();
    const {getValues, watch, setValue} = useFormContext<BaseMainProfiCardApplication>();
    const application = getValues();
    const isCompanyOwnerSectionVisible = isCompanyOwnerRequired(application);
    const isContactFormVisible = application.applicationType === ApplicationTypeDto.PROFI_CARD_DE_MAIN;
    const isSwiss = props.applicationType === ApplicationTypeDto.PROFI_CARD_CH_MAIN;

    const differentFromCardholder = watch('companyOwner.differentFromCardholder');
    const companyAddress = watch('company.address') as AddressWithAdditionalLine;
    const {
        salutation,
        title,
        firstName,
        lastName,
        dateOfBirth,
        email,
        cellPhoneNumber,
        phoneNumber,
    } = watch('cardHolder') as MainCardholder;

    useEffect(() => {
        if (!isCompanyOwnerSectionVisible || differentFromCardholder) {
            return;
        }
        setValue('companyOwner.salutation', salutation);
        setValue('companyOwner.title', title);
        setValue('companyOwner.firstName', firstName);
        setValue('companyOwner.lastName', lastName);
        setValue('companyOwner.dateOfBirth', dateOfBirth);
        setValue('companyOwner.email', isContactFormVisible ? email : '');
        setValue('companyOwner.cellPhoneNumber', isContactFormVisible ? cellPhoneNumber : '');
        setValue('companyOwner.phoneNumber', isContactFormVisible ? phoneNumber : '');
        setValue('companyOwner.address', {...companyAddress});
    }, [isCompanyOwnerSectionVisible, differentFromCardholder, setValue, companyAddress, salutation, title,
        firstName, lastName, dateOfBirth, email, cellPhoneNumber, phoneNumber, isContactFormVisible]);

    if (!isCompanyOwnerSectionVisible) {
        return null;
    }

    return (<FormSection title={t('application.form.companyOwner.sectionTitle')}
                         optional={{
                             optionalFieldPath: 'companyOwner.differentFromCardholder',
                             optionalCheckboxLabel: t('application.form.companyOwner.differentFromCardholder'),
                             readOnly: props.readOnly,
                             keepVisible: true,
                         }}>
        <FormRow>
            <SalutationSelectField<MainProfiCardDeApplication>
                    breakpoint={3}
                    fieldPath={'companyOwner.salutation'}
                    readOnly={props.readOnly}
                    disabled={!differentFromCardholder}/>
            <PersonTitleSelectField<MainProfiCardDeApplication>
                    breakpoint={3}
                    fieldPath={'companyOwner.title'}
                    readOnly={props.readOnly}
                    disabled={!differentFromCardholder}/>
        </FormRow>
        <FormRow>
            <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.firstName')}
                                                      fieldPath={'companyOwner.firstName'}
                                                      transformValue={trimAndCapitalizeFirstLetter}
                                                      readOnly={props.readOnly}
                                                      disabled={!differentFromCardholder}/>
            <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.lastName')}
                                                      fieldPath={'companyOwner.lastName'}
                                                      transformValue={trimAndCapitalizeFirstLetter}
                                                      readOnly={props.readOnly}
                                                      disabled={!differentFromCardholder}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<MainProfiCardDeApplication> breakpoint={3}
                                                                required={true}
                                                                fieldPath={'companyOwner.dateOfBirth'}
                                                                readOnly={props.readOnly}
                                                                disabled={!differentFromCardholder}/>
            {!isSwiss && <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                                   required={true}
                                                                   label={t('common.form.person.placeOfBirth')}
                                                                   fieldPath={'companyOwner.placeOfBirth'}
                                                                   readOnly={props.readOnly}/>}
        </FormRow>
        <FormRow>
            <NationalitySelectField<MainProfiCardDeApplication> breakpoint={3}
                                                                required={true}
                                                                fieldPath={'companyOwner.nationality'}
                                                                readOnly={props.readOnly}/>
        </FormRow>
        <AddressForm<MainProfiCardDeApplication> basePath={'companyOwner.address'} showAdditionalLine={true}
                                                 required={true}
                                                 readOnly={props.readOnly}
                                                 disabled={!differentFromCardholder}/>
        {isContactFormVisible && <ContactForm<MainProfiCardDeApplication> basePath={'companyOwner'}
                                                                          readOnly={props.readOnly}
                                                                          disabled={!differentFromCardholder}/>}
    </FormSection>);
};
